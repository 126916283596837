@import 'colors';
@import 'fonts';

.btn {
  background-clip: padding-box;
  background-color: $neutral-color;
  border: 1px solid $neutral-color;
  border-radius: 2px;
  color: white !important;
  cursor: pointer;
  display: inline-block;
  font-size: $text-size;
  margin: 0px;
  max-width: 100%;
  padding: 5px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease 0s;
  vertical-align: middle;

  &:active {
    padding: 4px 7px;
    margin: 1px;
  }
  
  &:focus {
    outline: 0;
    box-shadow: 0 0 10px 2px darken($neutral-color,5%);
  }

  &:hover {
    border-color: darken($neutral-color,5%);
  }

  &:disabled { 
    cursor: not-allowed;
  }

  &.btn-large {
    width: 180px;
    padding: 10px 30px;
    &:active {
      padding: 9px 29px;
    }
  }

  &.btn-long {
    min-width: 100px;
    padding: 10px 30px;
    &:active {
      padding: 9px 29px;
    }
  }
  &.btn-small {
    padding: 4px 5px;
    &:active {
      padding: 3px 4px;
    }
  }
  &.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    &:hover {
      border-color: darken($primary-color,5%);
    }
    &:disabled { 
      border-color: transparentize(darken($primary-color,5%),0.5);
      background-color: transparentize($primary-color,0.5);
    }
  }
  &.btn-info {
    background-color: $info-color;
    border-color: $info-color;
    &:hover {
      border-color: darken($info-color,5%);
    }
    &:disabled { 
      border-color: transparentize(darken($info-color,5%),0.5);
      background-color: transparentize($info-color,0.5);
    }
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    &:hover {
      border-color: darken($secondary-color,5%);
    }
    &:disabled { 
      border-color: transparentize(darken($secondary-color,5%),0.5);
      background-color: transparentize($secondary-color,0.5);
    }
  }

  &.btn-success {
    background-color: $success-color;
    border-color: $success-color;
    &:hover {
      border-color: darken($success-color,5%);
    }
    &:disabled { 
      border-color: transparentize(darken($success-color,5%),0.5);
      background-color: transparentize($success-color,0.5);
    }
  }

  &.btn-warning {
    background-color: $warning-color;
    border-color: $warning-color;
    &:hover {
      border-color: darken($warning-color,5%);
    }
    &:disabled { 
      border-color: transparentize(darken($warning-color,5%),0.5);
      background-color: transparentize($warning-color,0.5);
    }
  }

  &.btn-danger, &.btn-error {
    background-color: $error-color;
    border-color: $error-color;
    &:hover {
      border-color: darken($error-color,5%);
    }

    &:disabled { 
      border-color: transparentize(darken($error-color,5%),0.5);
      background-color: transparentize($error-color,0.5);
    }
  }

  &.btn-inverted {
    background-color: darken($neutral-color,25%);
    border-color: darken($neutral-color,35%);
    &:hover {
      border-color: darken($neutral-color,40%);
    }

    &:disabled { 
      border-color: transparentize(darken($neutral-color,5%),0.5);
      background-color: transparentize($neutral-color,0.5);
    }
  }
  &.icon {
    padding: 5px;
    line-height: 17px;
    &:active {
      padding: 4px;
    }
  }
}