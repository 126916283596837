$max-columns: 12;
$gutter: 5px;

.row {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
  min-height: 1px;
  width: 100%;
  
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.glued {
    justify-content: space-between;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  &-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    padding: ($gutter / 2);
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
  > .col, [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin col-x($size) {
  @for $i from 1 through $max-columns {
    .col#{$size}-#{$i} {
      flex: 0 0 percentage($i/$max-columns);
      max-width: percentage($i/$max-columns);
      min-height: 1px;
      padding: ($gutter / 2);
      position: relative;
    }
    .offset#{$size}-#{$i} {
      margin-left: percentage($i/$max-columns);
      min-height: 1px;
      padding: ($gutter / 2);
      position: relative;
    }
  }
}

@include col-x('');


// desktop
@media only screen and(min-width: 769px) {
  @include col-x(-md);
}