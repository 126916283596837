@import 'colors';
@import 'fonts';
@import 'mixins';

.message {
  border-radius: 2px;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 36px;
  padding: 8px;
  position: relative;
  .close-message {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}


.info-message {
  background-color: lighten($primary-color,40%);
  border: 1px solid darken($primary-color,15%);
  color: darken($primary-color,15%);
  .close-message {
    color: darken($primary-color,15%);
  }
}

.error-message {
  background-color: lighten($error-color,15%);
  border: 1px solid darken($error-color,15%);
  color: darken($error-color,15%);
  .close-message {
    color: darken($error-color,15%);
  }
}

.success-message {
  background-color: lighten($success-color,15%);
  border: 1px solid darken($success-color,15%);
  color: darken($success-color,15%);
  .close-message {
    color: darken($success-color,15%);
  }
}

.warning-message {
  background-color: lighten($warning-color,15%);
  border: 1px solid darken($warning-color,15%);
  color: darken($warning-color,15%);
  .close-message {
    color: darken($warning-color,15%);
  }
}

.default-message {
  background-color: lighten($neutral-color,15%);
  border: 1px solid darken($neutral-color,15%);
  color: darken($neutral-color,15%);
  .close-message {
    color: darken($neutral-color,15%);
  }
}