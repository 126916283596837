@import "framework/all";

footer {
  .ifsa-logo {
    height: 35px;
  }
}

.login-wrapper {
  background-image: url(/assets/images/login_background.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 550px;
  margin: 25px auto 0 auto;
}

.login {
  background-color: $secondary-color;
  border-radius: 2px;
  padding: 50px;
  .field-error-message {
    color: $primary-color;
  }
}

.login-background {
  display: block;
  margin: auto;
  max-width: 500px;
}

.company-filter {
  .form-field {
    display: inline-block;
    margin-bottom: 0;
    max-height: 48px;
    width: 180px;
  }
}

.tooltiped {
  position: relative;
  .tooltip {
    background-color: black;
    border: 1px solid $primary-color;
    border-radius: 2px;
    height: auto;
    min-height: 100%;
    top:105%;
    left: 0%;
    min-width: 80px;
    padding: 8px;
    position: absolute;
    z-index: 100;
    .tooltip-content {
      color: $neutral-color;
    }
  }
}

.listview {
  .listview-actions {
    i {
      font-size: 30px;
    }
  }
}