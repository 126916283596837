@import "colors";

.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  th, td {
    padding: 6px;
    text-align: left;
    word-wrap: break-word;
  }

  .header, .footer {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: white;
  }

  .body {
    background-color: lighten($neutral-color, 40%);
    border: 1px solid $primary-color;
    border-top: none;
    color: black;
  }

  &.stripped {
    .body {
      tr:nth-child(even) {
        background-color: lighten($neutral-color, 20%);
      }
    }
  }
  .actions {
    .btn {
      margin-right: 3px;
    }

    .btn:last-child {
      margin-right: 0;
    }
  }
}