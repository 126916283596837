@import 'colors';
@import 'fonts';
@import 'mixins';

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: $text-size;
  overflow-x: hidden;
  height: 100vh;
}

body {
  background-color: $main-background-color;
  color: $text-color;
  font-family: Arial;
  margin: 0;
  position: relative;
}

header {
  width: 100%;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: auto;
}

.main-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

footer {
  background-color: $primary-color;
  color: $neutral-color;
  padding: 5px 15px;;
}

section {
  margin-bottom: 15px;
}

nav {
  display: flex;
  text-align: center;

  &.vertical {
    flex-direction: column;
  }

  a {
    padding: 5px 13px;
    display: flex;
    position: relative;
    &[tooltip]:before {
      bottom: -65%;
      font-size: $text-size;
    }
  }
  .glue {
    height: 1px;
    flex-grow: 1;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: $title-size;
  font-weight: 300 !important;
  margin: 2px 0;
}

a, .link {
  color: $link-text-color;
  text-decoration: none;
  &:hover {
    color: darken($link-text-color,15%);
  }
  &.inverted {
    color: $neutral-color;
    &:hover {
      color: lighten($neutral-color, 15%);
    }
  }
}

.content {
  min-width: 960px;
  width: 100%;
}

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1230px;
  padding-top: 15px;
  width: 100%;
}

[tooltip]:before {   
  background-color: #555;
  bottom: -125%;
  border: 1px solid #333;
  border-radius: 2px;      
  color: white;
  content : attr(tooltip);
  opacity : 0;   
  line-height: 20px;
  min-width: 50px;
  padding: 2px 10px;
  position : absolute;
  transform:translateX(-33%);
  transition: opacity 0.3s;
  z-index: -100;
}
[tooltip]:hover:before {        
  opacity : 1;
  z-index: 100;
}

.labeled-span {
  display: table;
  border: 1px solid $primary-color;
  border-radius: 2px;
  width: 100%;

  label {
    background-color: $primary-color;
    color: white;
    display: table-cell;
    padding: 2px 5px;
    width: 20%;
    max-width: 20%;
    word-wrap: break-word;
  }

  span {
    border-left: none;
    display: table-cell;
    padding: 2px 5px;
    width: 100%;
  }
}

.box {
  border: 1px solid $primary-color;
  padding: 2px;

  .separator {
    border-color: $primary-color;
  }
}

.panel {
  background-color: lighten($neutral-color,15%);
  border: 1px solid $neutral-color;
  border-radius: 2px;
  color: darken($neutral-color, 25%);
  padding: 5px;
  p {
    margin: 5px 0;
  }
  .separator {
    border-color:  darken($neutral-color, 15%);
  }
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.collapsed {
  display: none;
  margin: 10px 0;
}

.separator {
  border-bottom: 1px dashed $primary-color;
  clear: both;
  min-height: 1px;
  margin-bottom: 15px;
  padding-top: 2px;
}

.loader {
  text-align: center;
  vertical-align: middle;
  font-size: 55px;
  i {
    vertical-align: middle;
  }
}

.pointer {
  cursor: pointer
}
