$main-background-color: white;

$primary-color: rgb(38,38,38);
$secondary-color: rgb(194,67,71);
$neutral-color: rgb(184,184,184);

$input-background-color: #FBFBFB;
$text-color: $primary-color;
$link-text-color: $text-color;
$navigation-link-color: white;

$info-color: rgb(64, 158, 255);
$error-color: rgb(232, 62, 62);
$warning-color: rgb(242, 142, 48);
$danger-color: $error-color;
$success-color: rgb(78, 166, 78);

$menu-background-color: $primary-color;
$submenu-background-color: $secondary-color;
$submenu-link-color: white;
$menu-bottom-color: rgb(255,194,16);