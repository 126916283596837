@import "colors";

$menu-size: 20px;
$submenu-size: 18px;
$title-size: 18px;
$text-size: 16px;
$input-text-size: 14px;
$small-size: 12px;

.text-info {
  color: darken($info-color,10%);
}
.text-danger {
  color: darken($danger-color,10%);
}
.text-success {
  color: darken($success-color,10%);
}
.text-warning {
  color: darken($warning-color,10%);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.title {
  border-bottom: 1px solid $secondary-color;
  font-size: $title-size;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 3px 8px;
}

.subtitle {
  border-bottom: 1px solid $secondary-color;
  font-size: $text-size;
  display: inline-block;
  margin-bottom: 5px;
}