@import 'colors';

.pagination {

  .btn-pagination {
    background-color: $primary-color;
    border-radius: 1px;
    margin: 0px 3px 0px 3px;

    &:active {
      margin: 0px 3px 0px 3px;
      padding: 5px 8px;
    }
  }

  .current-page {
    background-color: white;
    color: $primary-color !important;
    cursor: default;
  }

  .current-items {
    margin-right: 25px;
  }
}