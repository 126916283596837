@import "colors";
@import "fonts";

.label {
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: $small-size;
  padding: 3px;

  &-default {
    background-color: lighten($primary-color,15%);
    border-color: darken($primary-color,5%);
    color: darken($primary-color,20%);
  }
  &-success {
    background-color: lighten($success-color,15%);
    border-color: darken($success-color,5%);
    color: darken($success-color,20%);
  }
  &-warning {
    background-color: lighten($warning-color,15%);
    border-color: darken($warning-color,5%);
    color: darken($warning-color,20%);
  }
  &-danger {
    background-color: lighten($danger-color,15%);
    border-color: darken($danger-color,5%);
    color: darken($danger-color,20%);
  }
}