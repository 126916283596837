@import 'colors';
@import 'fonts';

fieldset {
  &.no-borders {
    border: none;
    margin: 0;
    padding: 0;
  }

  &:disabled {
    .dropdown-toggle {
      cursor: not-allowed;
      background-color: darken($input-background-color,15%) !important;
      * {
        background-color: darken($input-background-color,15%) !important;
      }
    }
  }

}

textarea, input[type="text"], input[type="password"], input[type="datetime"], 
input[type="datetime-local"], input[type="date"], input[type="month"], 
input[type="time"], input[type="week"], input[type="number"], 
input[type="email"], input[type="url"], input[type="search"], 
input[type="tel"], input[type="color"], select{
  background-clip: padding-box;
  background-color: $input-background-color;
  border: 1px solid $neutral-color;
  border-radius: 2px;
  color: $text-color;
  font-size: $input-text-size;
  line-height: 16px;
  outline:0;
  padding: 6px 12px;
  &:disabled {
    background-color: darken($input-background-color,15%) !important;
  }
}
.v-select {
  border: 1px solid $neutral-color;
  border-radius: 2px;
  font-size: $input-text-size;
  .dropdown-toggle {
    border: none !important;
  }
}

input[type="color"] {
  padding: 0;
}

input[type="file"] {
  box-shadow: none;
}

input[type="checkbox"]:checked, input[type="radio"]:checked, 
input[type="checkbox"]:focus, input[type="radio"]:focus {
  outline: medium none;
}

label {
  display: inline-block;
  line-height: 16px;
  padding: 8px 12px;
  text-align: right;
  vertical-align: middle;

  &.search {
    position: relative;
    margin: 0 10px 10px 0;
    &::before {
      bottom: 0;
      color: darken($text-color,10%);
      content: "\f002";
      display: block;
      font-family: FontAwesome;
      left: 0;
      overflow: hidden;
      padding-top: 6px;
      position: absolute;
      top: 0;
      width: 30px;
      text-align: center;
    }

    input[type="search"] {
      height: 30px;
      padding-left: 25px;
    }
  }
}

form, .form {

  &.form-horizontal {
    .form-group {
      display: flex;
      flex-wrap: wrap;
      .image-field {
        margin: 0 5px 5px 0;
        * {
          display:block;
          width: 100px;
        }
      }
    }
  }

  &.form-button {
    display: inline-block;
  }
  .form-field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    input[type=checkbox],input[type=radio] {
      flex: none;
    }
    .description {
      font-size: $small-size;
      font-style: italic;
    }
  }

  .label-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    label {
      text-align: right;
      min-width: 100%
    }
  }

  .field-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    position: relative;
    input, textarea, select, .v-select {
      width: 100%
    }
    input[type=checkbox],input[type=radio] {
      width: 18px;
      height: 18px;
      display: inline-block;
    }

    &.date-field:before {
      content: "\f073";
      font-family: "Font Awesome 5 Free";
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: $info-color;
      padding-right: 0.5em;
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
  .labeled-input {
    .label-wrapper{
      label {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        border-bottom: none;
        border-radius: 2px 0 0 2px;
        color: white;
      }
    }
    .form-control {
      border-left: none;
      border-radius: 0 2px 2px 0;
    }

    .dropdown-toggle {
      border-radius: 0 2px 2px 0;
    }
  }

  .form-control, select {
    background-color: $input-background-color;
    max-width: 100%;
    
    &:focus {
      box-shadow: inset 0 0 2px $primary-color;
      border-color: lighten($primary-color, 25%);
    }
  }

  .form-actions {
    margin-top: 15px;
  }

  .field-error-message {
    color: darken($error-color,25%);
    height: 15px;
  }

  .error-field {
    input,textarea {
      border: 1px solid $error-color;
      &:focus {
        box-shadow: inset 0 0 2px $error-color;
        border-color: lighten($error-color, 25%);
      }
    }
  }

  .disabled {
    * {
      background-color: darken($input-background-color,15%) !important;
    }
  }
}