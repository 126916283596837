@import 'colors';
@import 'fonts';
@import 'mixins';

.menu {
  background-color: $menu-background-color;
  border-bottom: 5px solid $menu-bottom-color;
  color: $navigation-link-color;
  display:block;
  font-size: $menu-size;
  line-height: 50px;
  min-height: 50px;
  a {
    @extend %soft-animation;
    color: $navigation-link-color;
    line-height: 34px;
    max-height: 45px;
    &.router-link-active, &:hover {
      color: $secondary-color;
    }
  }
  i {
    line-height: 34px;
  }
  .logo {
    object-fit: contain;
    height:30px;
  }
}

.submenu {
  background-color: $submenu-background-color;
  color: $submenu-link-color;
  font-size: $submenu-size;
  line-height: 36px;
  min-height: 36px;
  padding: 0 10px;
  a {
    color: $submenu-link-color;
  }
  a.router-link-active, a:hover {
    color: $menu-bottom-color;
  }
}

.section-title {
  background-color: $secondary-color;
  border-bottom: 10px solid $primary-color;
  color: $navigation-link-color;
  font-size: $title-size;
  padding: 5px 25px;
}